import React, { useState } from 'react';

function App() {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const response = await fetch('http://localhost:8080/users');
    const data = await response.json();
    setUsers(data);
  };

  return (
    <div>
      <h1>Главная страница React-приложения</h1>
      <button onClick={fetchUsers}>Загрузить пользователей</button>
      <ul>
        {users.map(user => (
          <li key={user.id}>{user.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;

